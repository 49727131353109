@import 'normalize.css';; /* bring in normalize.css styles */

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #1E1E1E;
    --secondary-color: #f5f5f5;
    --tertiary-color: #F9B301;

    --error-color: #C62026;
    --warning-color: #F9B301;
    --success-color: #235D29;

    --grey-300: #5c5c5c;
    --grey-050: #d6d6d6;

    --focus-color: #3c53d8;

    --shadow-color: rgba(0, 0, 0, 0);
    --step-shadow-color: rgba(0, 0, 0, 0);

    --button-border-color: var(--secondary-color);
  }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.paddingX10 {
  padding: 10px 0;
}

.paddingTop20 {
  padding-top: 20px;
}

.text-content {
  text-align: left !important;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.price-content {
  text-align: right !important;
  white-space: normal;
  flex-wrap: nowrap !important;
  /* overflow-wrap: break-word; */
}

:root {
  --primary-color: #F5F5F5;
  --secondary-color: #1E1E1E;
  --tertiary-color: #F9B301;

  --error-color: #C62026;
  --warning-color: #F9B301;
  --success-color: #235D29;

  --grey-300: #5c5c5c;
  --grey-050: #d6d6d6;

  --focus-color: #3c53d8;

  --shadow-color: rgba(30, 30, 30, .25);
  --step-shadow-color: rgba(165, 165, 165, 0.5);

  --button-border-color: var(--secondary-color);
}

/* [data-theme='dark'] {
  --primary-color: #1E1E1E;
  --secondary-color: #f5f5f5;
  --tertiary-color: #F9B301;

  --error-color: #C62026;
  --warning-color: #F9B301;
  --success-color: #235D29;

  --grey-300: #5c5c5c;
  --grey-050: #d6d6d6;

  --focus-color: #3c53d8;

  --shadow-color: rgba(0, 0, 0, 0);
  --step-shadow-color: rgba(0, 0, 0, 0);

  --button-border-color: var(--secondary-color);
} */