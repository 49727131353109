.App {
    width: 100vw;
    height: 100vh;

    text-align: center;
}

.AppContainer {
    width: 100%;
    min-height: 100vh;
    margin: 0;

    display: grid;
    grid-template-rows: 70px 1fr auto;
    grid-template-columns: 1fr;
}

.width100 {
    width: 100%;
}

.hideOnMobile {
    display: none;
}

.hideOnLaptop {
    display: inline;
}
  
@media screen and (min-width: 768px) {
    .AppContainer {
        grid-template-rows: 1fr auto;
    }
}

@media screen and (min-width: 1024px) {
    .laptopRow {
        display: flex;
        gap: 10px;
    }

    .laptopWidth100 {
        width: 100%;
    }

    .laptopWidth80 {
        width: 80%;
    }

    .laptopWidth40 {
        width: 40%;
    }

    .hideOnMobile {
        display: inline;
    }

    .hideOnLaptop {
        display: none;
    }
}

@media screen and (min-width: 1440px) {
    
}