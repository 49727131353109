.Header {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-items: center;
    align-items: flex-start;

    border-radius: 0 0 30px 30px;

    background-color: var(--tertiary-color);

    box-shadow: 0 2px 8px var(--shadow-color);

    z-index: 10;
}

.HeaderLogo {
    height: 60px;
    width: 60px;

    align-self: flex-start;

    margin-top: 6px;

    background-image: url('../../../assets/images/mallecupIcon.png');
    background-size: cover;
    background-position: center;
    filter: drop-shadow(0 0 8px var(--shadow-color));

    box-sizing: content-box;
}

.HeaderHeadline {
    color: var(--secondary-color);
    font-size: 1.35rem;
    font-weight: 800;

    align-self: center;

    flex-wrap: wrap;

    margin-top: 15px;
}
  
@media screen and (min-width: 768px) {
    .Header {
        display: none;
    }
}