.bodyContainerButtons {
    width: 100%;

    align-self: flex-end;

    display: flex;
    justify-content: space-between;
}

.buttonContainer {
    width: 100%;

    display: flex;
    justify-content: space-between;

    padding: 10px;
}

.bodyContainerButton {
    height: 40px;
    width: 120px;

    font-size: 1.25rem;

    color: var(--secondary-color);

    border: 1px solid var(--button-border-color);
    border-radius: 10px;
}

.bodyContainerButton:hover {
    transform: scale(1.025);
}

.continueButton {
    border: none;
    background-color: var(--tertiary-color);
}