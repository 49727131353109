.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modalContent h2 {
    margin-top: 0;
}

.modalContent button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: var(--tertiary-color);
    color: black;
    border-radius: 5px;
    cursor: pointer;
}