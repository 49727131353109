.eventCardContainer {
    width: 286px;
    height: 286px;

    border-radius: 15px;

    overflow: hidden;

    box-shadow: 0px 0px 10px var(--shadow-color);
}

.eventCardContainer:hover {
    box-shadow: 0px 0px 10px rgba(30, 30, 30, 0.45);
    transform: scale(1.025);
    transition: transform 0.3s ease;
}

.eventImage {
    width: 100%;
    height: 100%;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
    
}

@media screen and (min-width: 1024px) {
    .eventCardContainer {
        width: 328px;
        height: 328px;
    }
}

@media screen and (min-width: 1440px) {

}