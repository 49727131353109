.backgroundImage {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: -1;
}

.backgroundImageContainer {
    overflow: hidden;

    justify-content: center;
}

@media screen and (min-width: 320px) {
    .backgroundImage {
        background-color: var(--primary-color);
    }

    .backgroundImageContainer {
        display: none;
    }
}
  
@media screen and (min-width: 768px) {
    .backgroundImageContainer {
        display: flex;
    }
}