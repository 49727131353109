.inputElement {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0 0 10px 0;
}

.inputElementLabel {
    font-size: 18px;

    margin-bottom: 5px;
}

.inputElementInput {
	width: 100%;
    height: 40px;
    min-height: 40px;

    font-size: 18px;

	padding: 0 10px;

	outline: none;

	background: var(--primary-color);
	color: var(--secondary-color);

	border: 1px solid var(--secondary-color);
	border-radius: 10px;

    box-sizing: border-box;
}

.inputElementInput.multiline {
    width: 100%;
    height: 80px;
    resize: vertical; 
}

.inputElementInput::placeholder {
    color: var(--placeholder-color);
}

.inputElementInput:focus {
    border: 2px solid var(--focus-color);
}