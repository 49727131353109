.body {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.bodyHeader {
    display: none;
}

.bodyContainer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    overflow: hidden;
}
  
@media screen and (min-width: 768px) {
    .body {
        width: 711px;
        height: 100%;
        left: calc((100% - 711px) / 2);
        margin: auto;

        display: flex;
        flex-direction: column;
    }

    .bodyHeader {
        width: 100%;
        height: 100px;
        max-height: 100px;

        display: inline;
    }

    .bodyHeaderLogoBackground {
        position: relative;
        top: 6px;

        z-index: 1;
    }

    .bodyHeaderLogo {
        position: relative;
        top: -160px;
        left: calc((100% / 2) - 50px);

        height: 100px;
        width: 100px;

        z-index: 999;

        background-image: url('../../../assets/images/mallecupIcon.png');
        background-size: cover;
        background-position: center;
    }

    .bodyContainer {
        width: 711px;
        height: 644px;
        max-height: calc(100% - 100px);

        position: relative;
        top: -6px;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        border-radius: 25px;

        background-color: var(--primary-color);

        z-index: 2;
    }
}

@media screen and (min-width: 1024px) {
    .body {
        width: 731px;
    }
    
    .bodyContainer {
        width: 731px;
    }
}

@media screen and (min-width: 1440px) {
    .body {
        width: 1140px;
        height: 800px;
        margin: auto;

        display: flex;
        flex-direction: column;
    }

    .bodyHeaderLogo {
        height: 90px;
    }

    .bodyContainer {
        width: 100%;
        min-height: 700px;
        max-height: 700px;

        overflow: hidden;
    }
}