.bodyContainerSteps {
    height: 100%;
    width: 100%;
}

.stepsContent {
    height: calc(100% - 20px);
    width: calc(100% - 50px);

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;

    overflow-y: auto;

    border-radius: 20px;
    box-shadow: 0px 0px 10px var(--shadow-color);
}

.stepsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0 15px;
}

.stepHeadline {
    font-size: 2rem;
    font-weight: 900;

    padding: 10px 0;
}

.stepName {
    font-size: 1.25rem;
    font-weight: 700;

    padding: 2px 0 10px 0;
}

.horizontalDivider {
    width: 100%;
    height: 2px;

    background-color: var(--secondary-color);
}

.stepElements {
    width: 100%;

    padding: 10px 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.stepContent {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.stepElements .stepName {
    font-weight: 700;
    font-size: 1.1rem;
}

.stepElementsGroup {
    width: 100%;

    display: flex;
    flex-direction: row;

    gap: 10px;
}

.mobileThirdWith {
    width: 33%;
}

.stepElementsContainer {
    width: 100%;

    display: flex;
}

.laptopProgressIndicatorContainer {
    display: none;
}

.verticalDivider {
    display: none;
}

@media screen and (min-width: 768px) {
    .stepsContent {
        height: calc(100% - 20px - 20px);

        margin-top: 20px;

        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@media screen and (min-width: 1024px) {
    .mobileProgressIndicator {
        display: none;
    }

    .stepName {
        font-size: 1rem;
        font-weight: 600;
    }

    .horizontalDivider {
        display: none;
    }

    .stepHeadline {
        padding: 5px 0;
    }

    .stepElementsContainer {
        min-height: 480px;

        display: grid;
        grid-template-columns: auto auto 1fr;
        grid-template-rows: auto;

        padding: 10px 0;
    }

    .stepElements {
        width: 80%;
    }

    .verticalDividerContainer {
        padding: 0 30px;

        display: flex;
        align-items: center;
    }

    .verticalDivider {
        width: 2px;
        height: 100%;
    
        display: block;

        background-color: var(--grey-300);
    }

    .laptopProgressIndicatorContainer {
        height: 100%;
        width: 100%;

        display: grid;
        grid-template-columns: auto;
        grid-template-columns: 1fr auto;
    }

    .laptopProgressIndicator {
        height: 100%;
        width: 40px;

        padding: 0 5px 0 0;
    }

    #progressIndicatorSVG {
        height: 100%;
        width: 100%;
    }

    .laptopProgressIndicatorStepsContainer {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
    }

    .laptopProgressIndicatorSteps {
        width: 100%;
        height: calc(100% - 14px);

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
}

@media screen and (min-width: 1440px) {
    .stepsContent {
        height: calc(100% - 35px - 15px);

        margin-top: 35px;
    }

    .bodyContainerSteps {
        margin-left: calc((100% - 1140px) / 2 + 35px);
    }
}