.accomodationContainer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.leftAccomodations {
    align-self: flex-end;

    padding-top: 10px;
}

@media screen and (min-width: 1024px) {
    .accomodationContainer {
        display: flex;
        flex-direction: column;
        max-height: 460px;

        padding-right: 30px;

        overflow-y: auto;
    }
}