.homeContent {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    overflow-y: auto;
}

.homeChildContainer {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 15px;
    margin-bottom: 15px;

    gap: 25px;
}

@media screen and (min-width: 768px) {
    .homeContent {
        height: calc(100% - 25px);

        margin-top: 25px;
    }

    .homeChildContainer {
        width: 597px;

        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        gap: 25px;

        margin-left: calc((100% - 594px) / 2);
        margin-top: 25px;
        margin-bottom: 25px;
        box-sizing: content-box;
    }
}

@media screen and (min-width: 1024px) {
    .homeChildContainer {
        width: 1079px;

        margin-left: calc((100% - 681px) / 2);
    }
}

@media screen and (min-width: 1440px) {
    .homeContent {
        width: 1079px;
        height: 644px;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        position: relative;
        top: 30px;
        
        margin-top: 0;
        margin-left: calc((100% - 1079px) / 2 - 5px);

        overflow-y: auto;

        box-sizing: content-box;
    }

    .homeChildContainer {
        width: 1034px;

        display: flex;
        flex-wrap: wrap;

        margin-left: calc((100% - 1034px) / 2);

        gap: 25px;
    }
}