.paymentOverviewElementContainer {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.paymentOverviewElementHeader {

}

.paymentOverviewElementElements {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 5px;

    padding: 5px 0;
}

.paymentOverviewElementElementCotainer {
    width: calc(100% - 10px);
    display: flex;
    justify-content: space-between;

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;

    box-sizing: content-box;

    margin-left: 10px;
}

.paymentOverviewElementElementDescription {
    text-align: left;
}

.paymentOverviewElementElementPrice {
    
}