.overviewStepContent {
    gap: 1em;
}

.AGBLinkElement {
    display: flex;
    flex-direction: row;

    width: 100%;

    gap: .2em;

    padding-top: 10px;
}

.checkbox {
    width: 18px;
    height: 18px;
}

.Link {
    color: var(--secondary-color);
}

.Link:hover {
    color: var(--tertiary-color);
}

@media screen and (min-width: 1024px) {
    .overviewStepContent {
        display: flex;
        flex-direction: column;
        max-height: 460px;

        padding-right: 30px;

        overflow-y: auto;
    }
}